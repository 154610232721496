<template>
  <!-- 编辑网点 -->
  <div class="page-container">
    <div class="page-top-tool">
      <el-page-header @back="goBack" content="编辑网点"></el-page-header>
    </div>
    <div class="form-layout">
      <el-form :model="form" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <el-form-item label="网点编号">
          <el-input v-model="form.storecode" size="mini" style="width: 300px;"></el-input>
        </el-form-item>
        <el-form-item label="网点名称" prop="storename">
          <el-input v-model="form.storename" size="mini" placeholder="请输入网点名称" style="width: 300px;"></el-input>
        </el-form-item>
        <el-form-item label="所属地区">
          <el-select v-model="form.area" filterable clearable size="mini" placeholder="请选择地区" style="width: 300px;">
            <el-option v-for="item in areaOptions" :key="item.id" :label="item.name" :value="item.name"></el-option>
          </el-select>
          <el-button type="text" size="mini" style="margin-left: 10px;" @click="onAddArea">新增</el-button>
        </el-form-item>
        <el-form-item label="网点地址">
          <el-input v-model="form.address" type="textarea" :rows="2" size="mini" placeholder="请输入详细地址" style="width: 300px;"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="mini" @click="submitForm('ruleForm')">提 交</el-button>
          <el-button size="mini" @click="resetForm('ruleForm')">重 置</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UpdateStorePage',
  data() {
    return {
      form: {
        id: '',
        storecode: '',
        storename: '',
        area: '',
        address: ''
      },
      rules: {
        storecode: [
          { required: true, message: '请输入网点编号', trigger: 'blur' }
        ],
        storename: [
          { required: true, message: '请输入网点名称', trigger: 'blur' }
        ]
      },
      areaOptions: []
    };
  },

  mounted() {
    const store = sessionStorage.getItem('updateStore')
    if (store) {
      this.form = JSON.parse(store)
    } else {
      this.$message.warning('参数有误，请重新选择要修改的网点')
      this.$router.push({
        path: '/store'
      })
    }
    this.initData()
  },

  methods: {
    initData() {
      this.$http({
        method: 'post',
        url: 'area_findall'
      })
      .then(res => {
        if (res.errno == 0) {
          this.areaOptions = res.data.data
        } else {
          this.$message.error('地区查询失败')
        }
      })
      .catch(err => {
        this.$message.error('地区查询失败')
      })
    },
    goBack() {
      this.$router.back()
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
            this.$http({
              method: 'post',
              url: 'store_update',
              params: {
                ...this.form
              }
            })
            .then(res => {
              if (res.errno == 0) {
                this.$message.success('修改成功')
                this.resetForm('ruleForm')
              } else {
                this.$message.error('修改失败')
              }
            })
            .catch(err => {
              this.$message.error('修改失败，服务端异常')
              console.error('操作异常：', err.message);
            })
        } else {
          return false;
        }
      })
    },
    onAddArea() {
      this.$prompt('请输入地区名称', '添加', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }).then(({ value }) => {
          if (value) {
            this.$http({
              method: 'post',
              url: 'area_add',
              params: {
                name: value
              }
            })
            .then(res => {
              if (res.errno == 0) {
                this.initData()
                this.$message.success('地区添加成功')
              } else {
                this.$message.error('服务端异常，地区添加失败')
              }
            })
            .catch(err => {
              this.$message.error('服务端异常，地区添加失败')
            })
          } else {
            this.$message.warning('地区名称不能为空')
          }
        }).catch(() => {});
    }
  },
};
</script>

<style lang="scss" scoped>
.page-container {
  background: #fff;
  height: 100%;
  width: 100%;
  border-radius: 3px;
  box-sizing: border-box;
  padding: 20px 25px;
  overflow-y: scroll;

  .page-top-tool {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
  }

  .form-layout {
    margin: 30px 0px;
    padding: 10px 30px;
  }
}

/* 修改滚动条样式 */
.page-container::-webkit-scrollbar {
	width: 4px;
}
.page-container::-webkit-scrollbar-thumb {
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
	background: rgba(0, 0, 0, 0.2);
}
</style>